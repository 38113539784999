import PrimaryButton from '@/shared/components/Button/PrimaryButton';
import { Headline2, Paragraph1 } from '@/shared/components/Typography';
import { Box } from '@material-ui/core';
import Grid, { GridJustification } from '@material-ui/core/Grid';
import { ITextBaseLayoutProps } from './types';
import parse from 'html-react-parser';
import ButtonLink, { useButtonLinkStyles } from '@/shared/components/Button/ButtonLink'
import { useClub } from 'contexts/club';
import { getClubUrl } from '@/shared/utils/getClubUrl';
import { useLinkStyles } from '../Link/styles';
import { useTextSectionStyles } from './styles';

export default function TextBaseLayout(props: ITextBaseLayoutProps) {
    const { title, paragraph, buttonText, alignment, button, buttonLink, isClubExternalLink, openInNewWindow, onClickBtn } = props
    const justifyContent = {
        'left': 'flex-start',
        'right': 'flex-end',
        'center': 'center'
    }[alignment] as GridJustification
    const { club } = useClub()
    const classes = useButtonLinkStyles()
    const textClasses = useLinkStyles()
    const textSectionClasses = useTextSectionStyles()

    let link = buttonLink ?? null

    if (buttonLink && isClubExternalLink) {
        link = `${getClubUrl(club)}${buttonLink}`
    }

    function processHtmlString(): string {
        if (typeof window === 'undefined') return paragraph

        const htmlString = paragraph
        const container = document.createElement('div')
        container.innerHTML = htmlString
        const tables = container.querySelectorAll('table')

        tables.forEach(table => {
            table.className = textSectionClasses.table
            const div = document.createElement('div')
            div.className = textSectionClasses.tableWrapper
            table.parentNode.insertBefore(div, table)
            div.appendChild(table)
        })

        return container.innerHTML
    }

    return (
        <Box height='100%' display='flex' alignItems='center' textAlign={alignment}>
            <Grid container spacing={4}>
                {title && (
                    <Grid xs={12} item>
                        <Headline2>{title}</Headline2>
                    </Grid>
                )}
                {paragraph && (
                    <Grid xs={12} item className={textClasses.preLine}>
                        <Paragraph1>{parse(processHtmlString())}</Paragraph1>
                    </Grid>
                )}
                {button && (
                    <Grid xs={12} item container justifyContent={justifyContent}>
                        {onClickBtn ? (
                            <PrimaryButton className={classes.wordBreak} onClick={onClickBtn} href={link}>
                                {buttonText}
                            </PrimaryButton>
                        ) : (
                            <ButtonLink href={link} openInNewWindow={openInNewWindow}>
                                {buttonText}
                            </ButtonLink>
                        )}
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}